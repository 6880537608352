@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 20s linear;
  }
}

.Home-header {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: calc(10px + 1vmin);
}

.Deployment-details {
  padding: calc(10px + 1vmin);
}

.Root {
 flex-grow: 1;
}

.Home-link {
  color: #61dafb;
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  border: solid black 2px;
  text-align: left;
}
